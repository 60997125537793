import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import ArticleHero from 'components/Article/Hero';
import ArticleContent from 'components/Article/Content';

import Footer from 'components/Footer';
import RecentPosts from 'components/RecentPosts';
import ScrollSection from 'components/ScrollSection';
import SEO from 'components/SEO';
import { colors } from 'styles/vars/colors.style';

const ArticleTemplate = ({ data }) => {
  const [filteredArticles, setFilteredArticles] = useState([]);

  const relatedArticles = data.allContentfulPagePost.nodes;

  useEffect(() => {
    const filtered = relatedArticles.filter(
      (article) => article.heroTitle !== data.contentfulPagePost.heroTitle,
    );
    setFilteredArticles(filtered.slice(0, 3));
  }, [relatedArticles, setFilteredArticles, data.contentfulPagePost.heroTitle]);

  const {
    title,
    seoImage,
    heroTitle,
    slug,
    soundcloudEmbed,
    publishedDate,
    typeOfPost,
    author,
    mainContent,
  } = data.contentfulPagePost;

  return (
    <>
      <SEO
        title={title}
        image={seoImage ? `https:${seoImage.file.url}` : ''}
        description={heroTitle}
        pathname={`blog/${slug}`}
      />
      <ScrollSection>
        <ArticleHero
          title={heroTitle}
          date={publishedDate}
          typeOfPost={typeOfPost}
          author={author}
          podcast={soundcloudEmbed}
        />
      </ScrollSection>

      {mainContent && <ArticleContent content={mainContent} title={title} />}

      {filteredArticles.length > 2 && (
        <ScrollSection>
          <RecentPosts content={filteredArticles} />
        </ScrollSection>
      )}

      <Footer backgroundColor={colors.neutralWhite} />
    </>
  );
};

export default ArticleTemplate;

export const articleQuery = graphql`
  query ArticlePageBySlug($slug: String!) {
    contentfulPagePost(slug: { eq: $slug }) {
      slug
      title
      publishedDate(formatString: "MM/DD/YYYY")
      seoImage {
        file {
          url
        }
      }
      author {
        name
      }
      heroColorTheme
      heroTitle
      heroCopy {
        raw
      }
      typeOfPost
      heroImage {
        title
        description
        file {
          url
        }
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      soundcloudEmbed {
        ... on ContentfulSoundcloudEmbed {
          title
          seriesTitle
          soundcloudId
        }
        ... on ContentfulApplePodcastsEmbed {
          id
          title
          seriesTitle
          applePodcastId
        }
        ... on ContentfulSpotifyTrackEmbed {
          title
          seriesTitle
          spotifyTrackId
        }
      }
      mainContent {
        ... on ContentfulBlockVimeoVideo {
          title
          vimeoId
        }
        ... on ContentfulBlockYouTubeVideo {
          youTubeVideoId
          title
        }
        ... on ContentfulBlockArticleText {
          id
          articleText {
            raw
          }
        }
        ... on ContentfulBlockArticleCarousel {
          id
          carouselItem {
            image {
              fluid(maxWidth: 931) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              title
            }
            text
          }
        }
        ... on ContentfulBlockImage {
          id
          image {
            contentful_id
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            description
          }
        }
        ... on ContentfulBlockCodeSnippet {
          id
          snippet {
            raw
          }
        }
      }
    }
    allContentfulPagePost(
      limit: 4
      filter: { heroTitle: { ne: "dummy article" } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      nodes {
        slug
        heroTitle
        heroCopy {
          raw
        }
        typeOfPost
        heroImage {
          title
          description
          fluid(maxWidth: 800, maxHeight: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
