import styled from 'styled-components';
import { clamp } from '../../../styles/utils/conversion.style';

export const ArticleContent = styled.div`
  position: relative;
  ${clamp('padding-bottom', 16, 30)}

  p {
    ${clamp('margin-bottom', 24, 40)}
  }

  .split__mask,
  .split__text {
    display: inline !important;
  }
`;
