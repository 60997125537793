import React from 'react';
import styled from 'styled-components';
import { clamp } from 'styles/utils/conversion.style';

import AnimateFadeIn from 'components/_animation/AnimateFadeIn';

const ArticleImage = ({ src, alt, width, height }) => {
  return (
    <Wrapper>
      <AnimateFadeIn>
        <Image src={src} alt={alt} width={width} height={height} />
      </AnimateFadeIn>
    </Wrapper>
  );
};

export default ArticleImage;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  width: ${(props) => props.width}px;
  height: auto;
  border-radius: 0.5rem;
  ${clamp('margin-bottom', 16, 30)}
  transform: translate3d(0, 0, 0);
`;
