import styled from 'styled-components';
import { mq } from '../../styles/vars/media-queries.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import DotMask from './images/dot-mask.svg';
import { clamp } from '../../styles/utils/conversion.style';
import { grid } from '../../styles/vars/grid.style';

export const HomeCarouselMain = styled.div`
  ${({ reverse }) => {
    if (reverse) {
      return `
        margin-left: -2.2rem;

        ${mq.tabletP} {
          padding-left: 2.2rem;
        }
      `;
    } else {
      return `
        margin-right: -2.2rem;

        ${mq.tabletP} {
          padding-right: 2.2rem;
        }
      `;
    }
  }}

  ${({ full }) =>
    full
      ? `
        margin-bottom: 8rem;
        margin-right: 0rem;

      ${mq.tabletP} {
        margin-right: -2.2rem;
        padding-right: 0;
        padding-left:0;}`
      : ``}

  ${({ article }) =>
    article &&
    `
      ${clamp('margin-bottom', 40, 70)}

      ${mq.tabletP} {
        margin-right: 0;
      }
    `}
  padding-top: 3rem;
  overflow: hidden;

  ${mq.tabletP} {
    padding-top: 0;
  }
`;

export const HomeCarouselTrack = styled.div`
  position: relative;
`;

export const HomeCarouselNext = styled.div`
  position: absolute;
  bottom: 0;
  ${({ reverse }) => {
    if (reverse) {
      return clamp('right', grid.gap.min, grid.gap.max);
    } else {
      return clamp('left', grid.gap.min, grid.gap.max);
    }
  }}
  width: 100%;
  height: 100%;
  opacity: 0;
  transform-origin: ${({ reverse }) => (reverse ? '100% 100%' : '0% 100%')};
  background-image: ${({ full }) => (full ? `none` : `url(${DotMask})`)};
  background-position: center;
  border-radius: ${({ reverse }) =>
    reverse ? '0 0.5rem 0.5rem 0' : '0.5rem 0 0 0.5rem'};
  overflow: hidden;
  will-change: transform;

  [data-inview='true'] & {
    opacity: 1;
    transition: opacity ${easings.image.colourIn} 1s 1s;
  }

  ${mq.tabletP} {
    border-radius: 0.5rem;
  }
`;

export const HomeCarouselNextImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const HomeCarouselSlide = styled.div`
  position: ${({ active }) => (active ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  border-radius: ${({ reverse }) =>
    reverse ? '0 0.5rem 0.5rem 0' : '0.5rem 0 0 0.5rem'};
  overflow: hidden;
  transform-origin: ${({ reverse }) => (reverse ? '0% 100%' : '100% 100%')};

  ${mq.tabletP} {
    border-radius: 0.5rem;
  }
`;
export const HomeCarouselCaptionsContainer = styled.div`
  position: relative;
`;

export const HomeCarouselCaptions = styled.div`
  position: relative;

  ${mq.tabletL} {
    display: inline-block;
    width: 70%;
  }
`;

export const HomeCarouselHidden = styled.div`
  opacity: 0;
`;

export const HomeCarouselCaption = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${({ reverse }) => {
    if (reverse) {
      return 'padding-left: 2.2rem;';
    } else {
      return 'padding-right: 2.2rem;';
    }
  }}
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.4s ${easings.text.in};

  ${mq.tabletP} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const HomeCarouselDots = styled.ul`
  display: flex;
  align-items: center;
  ${({ reverse }) => {
    if (reverse) {
      return 'padding-left: 2.2rem;';
    } else {
      return 'padding-right: 2.2rem;';
    }
  }}
  list-style: none;

  ${mq.tabletP} {
    padding-left: 0;
    padding-right: 0;
  }

  ${mq.tabletL} {
    display: inline-flex;
    position: absolute;
    right: 0;
    ${clamp('top', 18, 24)}
  }

  button {
    :first-of-type {
      margin-right: 0.7em;

      ${({ min }) =>
        min === 'true'
          ? `pointer-events: none; opacity: .4;`
          : `pointer-events:all; opacity: 1;`}
    }
    :last-of-type {
      margin-left: 0.7em;

      ${({ max }) =>
        max === 'true'
          ? `pointer-events: none; opacity: .4;`
          : `pointer-events:all; opacity: 1;`}
    }
  }
`;

export const HomeCarouselDot = styled.li``;

export const HomeCarouselDotBtn = styled.button`
  position: relative;
  width: 2.2rem;
  height: 3.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 100%;
  background: none;
  border: none;
`;

export const HomeCarouselDotInner = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${({ active }) =>
    active ? colors.humeBlack700 : 'transparent'};
  border-radius: 50%;
  border: 0.1rem solid ${colors.humeBlack700};
  transition: background-color 0.15s ${easings.inOut.quad};

  ${HomeCarouselDotBtn}:hover & {
    background-color: ${colors.humeBlack700};
  }
`;
