import React, { useState } from 'react';
import styled from 'styled-components';
import Share from './Share';
import ArticleCopy from 'components/Article/Copy';
import ArticleImage from 'components/Article/Content/Image';
import ArticleCodeSnippet from 'components/Article/Content/CodeSnippet';
import HomeCarousel from 'components/HomeCarousel';
import Video from 'components/Video/video';

import Container from 'components/_layouts/Container';
import Grid from 'components/_layouts/Grid';
import GridItem from 'components/_layouts/GridItem';
import ScrollSection from 'components/ScrollSection';
import { mq } from 'styles/vars/media-queries.style';
import { colors } from 'styles/vars/colors.style';

const ArticleContent = ({ content, title }) => {
  const [isHidden, setIsHidden] = useState(false);

  const fragments = content.map((contentItem, key) => {
    switch (contentItem.__typename) {
      case 'ContentfulBlockArticleText':
        return <ArticleCopy content={contentItem.articleText} />;

      case 'ContentfulBlockArticleCarousel':
        return (
          <HomeCarousel
            items={contentItem.carouselItem}
            autoplay={false}
            full
          />
        );

      case 'ContentfulBlockVimeoVideo':
        return (
          <Video
            videoType={'Vimeo'}
            videoProps={contentItem.vimeoId}
            isHidden={isHidden}
            setIsHidden={setIsHidden}
          />
        );

      case 'ContentfulBlockYouTubeVideo':
        console.log('youtube');
        return (
          <Video
            videoType={'YouTube'}
            videoProps={contentItem.youTubeVideoId}
            isHidden={isHidden}
            setIsHidden={setIsHidden}
          />
        );

      case 'ContentfulBlockImage':
        return (
          <ArticleImage
            src={contentItem.image.file.url}
            alt={contentItem.image.description}
            width={contentItem.image.file.details.image.width}
            height={contentItem.image.file.details.image.height}
          />
        );

      case 'ContentfulBlockCodeSnippet':
        return <ArticleCodeSnippet content={contentItem.snippet} />;

      default:
        return null;
    }
  });

  return (
    <ScrollSection>
      <Wrapper id="article-content">
        <Content>{fragments}</Content>
        <ShareWrapper>
          <Share title={title} />
        </ShareWrapper>
      </Wrapper>
    </ScrollSection>
  );
};

export default ArticleContent;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 75px 22px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${colors.neutralWhite};

  ${mq.tabletP} {
    padding: 150px 70px 0;
  }

  ${mq.desk} {
    flex-direction: row;
    padding: 150px 133px 0;
    gap: 115px;
  }

  ${mq.contained} {
    gap: 5.99vw;
    padding: 7.81vw 6.93vw 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 1;

  ${mq.tabletP} {
  }

  ${mq.desk} {
    width: 100%;
  }

  ${mq.contained} {
  }
`;

const ShareWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;

  ${mq.desk} {
    width: 244px;
  }

  ${mq.deskL} {
    width: 360px;
  }

  ${mq.contained} {
    width: 18.75vw;
  }
`;
