import React from 'react';
import RichText from 'components/RichText';
import { ArticleContent } from './index.style';

const ArticleRichText = ({ content }) => (
  <ArticleContent>
    {content && <RichText content={content} delay={0} />}
  </ArticleContent>
);

export default ArticleRichText;
