import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Hammer from 'react-hammerjs';
import { TextBodyLarge } from '../TextStyles';

import {
  HomeCarouselMain,
  HomeCarouselTrack,
  HomeCarouselSlide,
  HomeCarouselDots,
  HomeCarouselCaptions,
  HomeCarouselCaptionsContainer,
  HomeCarouselCaption,
  HomeCarouselHidden,
  HomeCarouselNext,
  HomeCarouselNextImg,
} from './index.style';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import Spacer from '../Spacer';
import gsap from 'gsap';
import { useCallback } from 'react';
import Button from '../Button';

//Arrow SVGs
import IconArrowheadLeft from '../_svgs/IconArrowheadLeft';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';

//For build ons
import AnimateImageIn from '../_animation/AnimateImageIn';
import { breakpoints } from '../../styles/vars/breakpoints.style';

const HomeCarousel = ({ autoplay, items, reverse, full, article }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [dotIndex, setDotIndex] = useState(0);
  const [timerStarted, setTimerStarted] = useState(false);
  const imageRefs = useRef([]);
  const nextImageRefs = useRef([]);
  const nextSlideRef = useRef();
  const loadingSlideRef = useRef();
  const tl = useRef();

  const carouselTimer = useRef(),
    carouselRef = useRef();

  const timerLength = 5000;

  const animation = {
    ease: 'expo.inOut',
    scaleTarget: 0.7,
    duration: 0.9,
  };

  const longestCaption = items.reduce((a, b) =>
    a.text.length > b.text.length ? a : b,
  );

  const resetElements = useCallback(() => {
    gsap.set(nextSlideRef.current, {
      xPercent: reverse ? -200 : 200,
      scale: animation.scaleTarget,
    });

    gsap.set(loadingSlideRef.current, {
      xPercent: reverse ? -100 : 100,
      x: 0,
      scale: animation.scaleTarget,
    });

    gsap.set(nextImageRefs.current, {
      opacity: 0,
    });
  }, [reverse, animation.scaleTarget]);

  useEffect(() => {
    resetElements();

    return () => {
      if (tl.current) {
        tl.current.kill();
      }
    };
  }, [resetElements]);

  const handleSwipe = (e) => {
    if (window.innerWidth < breakpoints.tabletL) {
      if (e.direction === 4) {
        //left
        if (dotIndex > 0) {
          updateSlide(dotIndex - 1);
        }
      } else if (e.direction === 2) {
        //right
        if (dotIndex < items.length - 1) {
          updateSlide(dotIndex + 1);
        }
      }
    }
  };

  const startTimer = useCallback(() => {
    setTimerStarted(true);
  }, [setTimerStarted]);

  const deleteTimer = useCallback(() => {
    if (carouselTimer.current) {
      clearTimeout(carouselTimer.current);
    }
  }, [carouselTimer]);

  const updateSlide = useCallback(
    (index) => {
      deleteTimer();

      const currentIndex = slideIndex;
      tl.current = gsap.timeline({
        onComplete: () => {
          setSlideIndex(index);
          startTimer();
          resetElements();
          gsap.set(imageRefs.current[currentIndex], {
            clearProps: 'transform',
          });
        },
      });
      setDotIndex(index);

      tl.current
        .to(
          nextImageRefs.current[index],
          {
            opacity: 1,
            duration: animation.duration,
            ease: animation.ease,
          },
          0,
        )
        .to(
          nextSlideRef.current,
          {
            xPercent: reverse ? -100 : 100,
            duration: animation.duration,
            ease: animation.ease,
          },
          0,
        )
        .to(
          loadingSlideRef.current,
          {
            xPercent: 0,
            x: `${reverse ? '+' : '-'}=${window
              .getComputedStyle(loadingSlideRef.current)
              .getPropertyValue(reverse ? 'right' : 'left')
              .slice(0, -2)}`,
            scale: 1,
            duration: animation.duration,
            ease: animation.ease,
          },
          0,
        )
        .to(
          imageRefs.current[currentIndex],
          {
            xPercent: reverse ? 100 : -100,
            scale: animation.scaleTarget,
            duration: animation.duration - 0.1,
            ease: animation.ease,
          },
          0,
        );
    },
    [
      animation.duration,
      animation.ease,
      animation.scaleTarget,
      deleteTimer,
      startTimer,
      resetElements,
      reverse,
      slideIndex,
    ],
  );

  useEffect(() => {
    if (timerStarted === true) {
      carouselTimer.current = setTimeout(() => {
        if (slideIndex === items.length - 1) {
          updateSlide(0);
        } else {
          updateSlide(slideIndex + 1);
        }
      }, timerLength);
    }
  }, [
    timerStarted,
    carouselTimer,
    slideIndex,
    items.length,
    timerLength,
    updateSlide,
  ]);

  useEffect(() => {
    return () => {
      deleteTimer();
    };
  }, [deleteTimer]);

  useEffect(() => {
    autoplay && startTimer();
  }, [startTimer, autoplay]);

  return (
    <HomeCarouselMain
      reverse={reverse}
      ref={carouselRef}
      full={full}
      article={article}
    >
      <Grid tabletP={8}>
        <GridItem
          tabletP={7}
          tabletPStart={full ? 1 : reverse ? 2 : 1}
          desk={full ? 8 : 6}
          deskStart={reverse ? 3 : 1}
        >
          <Hammer onSwipe={handleSwipe}>
            <HomeCarouselTrack>
              <HomeCarouselNext ref={nextSlideRef} reverse={reverse} />

              <HomeCarouselNext
                ref={loadingSlideRef}
                reverse={reverse}
                full={full}
              >
                {React.Children.toArray(
                  items.map((item, itemIndex) => (
                    <HomeCarouselNextImg
                      ref={(ref) => (nextImageRefs.current[itemIndex] = ref)}
                      active={slideIndex === itemIndex}
                    >
                      <AnimateImageIn delay={0}>
                        <Img fluid={item.image.fluid} alt={item.image.title} />
                      </AnimateImageIn>
                    </HomeCarouselNextImg>
                  )),
                )}
              </HomeCarouselNext>

              {React.Children.toArray(
                items.map((item, itemIndex) => (
                  <HomeCarouselSlide
                    ref={(ref) => (imageRefs.current[itemIndex] = ref)}
                    active={slideIndex === itemIndex}
                    reverse={reverse}
                  >
                    <AnimateImageIn delay={0}>
                      <Img fluid={item.image.fluid} alt={item.image.title} />
                    </AnimateImageIn>
                  </HomeCarouselSlide>
                )),
              )}
            </HomeCarouselTrack>
          </Hammer>
          <HomeCarouselCaptionsContainer>
            <Spacer size={[18, 24]} />
            <HomeCarouselCaptions>
              <HomeCarouselHidden aria-hidden>
                <Spacer size={[18, 24]} />
                <TextBodyLarge>{longestCaption.text}</TextBodyLarge>
              </HomeCarouselHidden>

              {React.Children.toArray(
                items.map((item, index) => (
                  <HomeCarouselCaption
                    active={slideIndex === index}
                    reverse={reverse}
                  >
                    <TextBodyLarge>{item.text}</TextBodyLarge>
                  </HomeCarouselCaption>
                )),
              )}
            </HomeCarouselCaptions>

            <HomeCarouselDots
              reverse={reverse}
              max={dotIndex === items.length - 1 ? 'true' : 'false'}
              min={dotIndex === 0 ? 'true' : 'false'}
            >
              <Button
                variant={'plain'}
                onClick={(e) => {
                  e.preventDefault();
                  if (dotIndex > 0) {
                    updateSlide(dotIndex - 1);
                  }
                }}
                left
                ariaLabel={`Previous Image`}
                iconOnly={<IconArrowheadLeft />}
              ></Button>
              {dotIndex + 1} of {items.length}
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  if (dotIndex < items.length - 1) {
                    updateSlide(dotIndex + 1);
                  }
                }}
                ariaLabel={`Next Image`}
                variant={'plain'}
                iconOnly={<IconArrowheadRight />}
              ></Button>
            </HomeCarouselDots>
            <Spacer size={[60, 180]} />
          </HomeCarouselCaptionsContainer>
        </GridItem>
      </Grid>
    </HomeCarouselMain>
  );
};

HomeCarousel.propTypes = {
  items: PropTypes.array,
  reverse: PropTypes.bool,
};

export default HomeCarousel;
