import React from 'react';
import styled from 'styled-components';
import { mq } from 'styles/vars/media-queries.style';

const Podcast = ({ podcast }) => {
  return (
    <>
      {podcast?.soundcloudId && (
        <SoundcloudContainer>
          <iframe
            width="100%"
            height="150"
            allow="autoplay"
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${podcast.soundcloudId}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=false`}
            title={`SoundcloudEmbed`}
          ></iframe>
        </SoundcloudContainer>
      )}

      {podcast?.applePodcastId && (
        <SoundcloudContainer>
          <iframe
            allow="autoplay *; encrypted-media *; fullscreen *"
            height="180"
            width="100%"
            frameBorder={0}
            scrolling="no"
            className={`applepod`}
            title={`AppleEmbed`}
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
            src={`https://embed.podcasts.apple.com/us/podcast/${podcast.applePodcastId}`}
          ></iframe>
        </SoundcloudContainer>
      )}

      {podcast?.spotifyTrackId && (
        <SoundcloudContainer>
          <iframe
            src={`https://open.spotify.com/embed/track/${podcast.spotifyTrackId}`}
            width="100%"
            height="80"
            allowtransparency="true"
            allow="encrypted-media"
            title={`SpotifyEmbed`}
          ></iframe>
        </SoundcloudContainer>
      )}
    </>
  );
};

export default Podcast;

const SoundcloudContainer = styled.div`
  overflow: hidden;
  width: 100%;
  margin-top: 75px;

  ${mq.tabletL} {
    margin-top: 100px;
  }

  ${mq.contained} {
    margin-top: 5.21vw;

    iframe {
      border-radius: 0.78vw;
    }
  }

  iframe {
    border-radius: 15px;
  }

  .applepod {
    width: 100%;
    overflow: hidden;
    background: transparent;
  }
`;
