import React from 'react';
import styled from 'styled-components';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { TextBodySmall } from 'components/TextStyles';
import AnimateFadeIn from 'components/_animation/AnimateFadeIn';

import { colors } from 'styles/vars/colors.style.js';
import { clamp } from 'styles/utils/conversion.style';

const CodeSnippet = ({ content }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <TextBodySmall>{children}</TextBodySmall>
      ),
    },
  };

  return (
    <AnimateFadeIn>
      <Wrapper>{renderRichText(content, options)}</Wrapper>
    </AnimateFadeIn>
  );
};

export default CodeSnippet;

const Wrapper = styled.pre`
  background: ${colors.humeBlack400};
  color: ${colors.humeTan400};
  white-space: break-spaces;
  word-break: break-all;

  border-radius: 0.5rem;
  padding: 3rem;
  ${clamp('margin-bottom', 16, 30)}
`;
