import { clamp } from '../../styles/utils/conversion.style';
import styled from 'styled-components';
import { easings } from '../../styles/vars/easings.style';

export const VideoWrap = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 2;
  ${clamp('margin-bottom', 50, 70)}

  .embed-container {
    position: relative;
    aspect-ratio: 16 / 9;
    transition: opacity 0.5s ${easings.text.in};
    width: 100%;
  }

  ${({ isHidden }) => {
    return isHidden === true
      ? `.embed-container {opacity: 0; }`
      : `.embed-container {opacity: 1; transition-delay: .5s;}`;
  }}

  img,
  .gatsby-image-wrapper {
    opacity: 1;
    transform: scale(1.2);
    z-index: 2;
  }

  [inview='true'] &,
  .is-inview & {
    img,
    .gatsby-image-wrapper {
      transform: scale(1.01);
      transition: transform 1.5s $aegisMask 0.5s;
    }

    .hidden {
      img,
      .gatsby-image-wrapper {
        opacity: 0;
        transition: opacity 1s $aegisOut;
      }
    }
  }

  //global video stuff
  .embed-inner {
    padding-bottom: 125%;
    position: relative;
    overflow: hidden;
  }

  /* .embed-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    right: 0;
  } */

  .embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    border: 0;
    /* height: 100%;
    left: 0;
    /* pointer-events: none; */
    /* position: absolute;
    top: 0; */
    width: 100%;
    /* @include tabletL {
      width: 85vh;
    } */
  }
`;
