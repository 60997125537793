import styled from 'styled-components';
import { colors } from '../../../styles/vars/colors.style';
import { mq } from '../../../styles/vars/media-queries.style';

export const Wrapper = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 22px 0;
  background-color: ${colors.neutralWhite};

  ${mq.tabletP} {
    padding: 80px 70px 0;
  }

  ${mq.desk} {
    padding: 166px 133px 0;
  }

  ${mq.contained} {
    padding: 8.65vw 6.93vw 0;
  }
`;

export const BackToHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  ${mq.contained} {
    margin-bottom: 2.6vw;
  }
`;

export const Inner = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.humeBlack700};
  padding-bottom: 75px;

  ${mq.tabletL} {
    padding-bottom: 100px;
  }

  ${mq.contained} {
    padding-bottom: 5.21vw;
    border-width: 0.05vw;
  }
`;

export const ArticleImage = styled.div`
  border-radius: 4px;
  margin-bottom: 50px;
  width: 100%;
  height: 344px;
  overflow: hidden;

  ${mq.tabletP} {
    height: 450px;
  }

  ${mq.contained} {
    height: 23.44vw;
    margin-bottom: 2.6vw;
    border-radius: 0.21vw;
  }

  img,
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const VR = styled.div`
  display: none;
  width: 2px;
  background-color: black;

  ${mq.tabletP} {
    display: flex;
  }
`;

export const TitleDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;

  div {
    color: ${colors.humeTan700};

    a {
      color: ${colors.humeBlack700};
    }
  }

  h1 {
    max-width: 1200px;
  }

  ${mq.contained} {
    gap: 0.52vw;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 37px;
  height: auto;

  ${mq.tabletP} {
    flex-direction: row;
  }

  ${mq.contained} {
    gap: 1.93vw;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${mq.contained} {
    gap: 0.52vw;
  }

  p {
    display: none;

    ${mq.mobileL} {
      display: block;
    }
  }
`;

export const SocialLink = styled.a`
  border-radius: 50%;
  border: 1px solid currentColor;
  height: 40px;
  position: relative;
  width: 40px;

  svg {
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 40px;
  }

  ${mq.contained} {
    width: 2.08vw;
    height: 2.08vw;
    border-width: 0.05vw;

    svg {
      width: 2.08vw;
    }
  }
`;

export const PodcastWrapper = styled.div`
  padding: 0 ${(props) => props.padding}px;
  width: 100%;
`;
