import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/vars/colors.style';
import { Heading7 } from 'components/TextStyles';
import X from 'components/_svgs/X';
import LinkedIn from 'components/_svgs/LinkedIn';
import { TextStyle } from 'styles/vars/textStyles.style.js';
import { mq } from 'styles/vars/media-queries.style';
import Subscribe from 'components/Subscribe';
import { useMedia } from 'hooks/useMedia';

const Share = ({ title }) => {
  const currentPage = useRef(null);
  const twitterLinkUrl = useRef(null);
  const linkedInLinkUrl = useRef(null);
  const encodedText = useRef(null);

  useEffect(() => {
    currentPage.current = encodeURIComponent(window.location.href);
    encodedText.current = encodeURIComponent(title);
    twitterLinkUrl.current = `https://twitter.com/intent/tweet?text=${encodedText.current}&url=${currentPage.current}`;
    linkedInLinkUrl.current = `https://www.linkedin.com/shareArticle?mini=true&url=${currentPage.current}`;
  }, [title]);

  const sticky = useMedia(true, true, true, false, false, false, false, false);

  if (sticky) {
    return (
      <Wrapper
        data-scroll
        data-scroll-sticky
        data-scroll-target="#article-content"
        data-scroll-offset="0, 250"
      >
        <Title>
          <Heading7>SUBSCRIBE NOW</Heading7>
        </Title>
        <Subscribe />
        <ShareTitle>
          <Heading7>SHARE ARTICLE</Heading7>
        </ShareTitle>
        <SocialLinks>
          {linkedInLinkUrl.current && (
            <SocialLink
              href={linkedInLinkUrl.current}
              aria-label={`Share on LinkedIn`}
              target="_blank"
            >
              <LinkedIn />
            </SocialLink>
          )}
          {twitterLinkUrl.current && (
            <SocialLink
              href={twitterLinkUrl.current}
              aria-label={`Share on Twitter`}
              target="_blank"
            >
              <X />
            </SocialLink>
          )}
        </SocialLinks>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Title>
          <Heading7>SUBSCRIBE NOW</Heading7>
        </Title>
        <Subscribe />
        <ShareTitle>
          <Heading7>SHARE ARTICLE</Heading7>
        </ShareTitle>
        <SocialLinks>
          {linkedInLinkUrl.current && (
            <SocialLink
              href={linkedInLinkUrl.current}
              aria-label={`Share on LinkedIn`}
              target="_blank"
            >
              <LinkedIn />
            </SocialLink>
          )}
          {twitterLinkUrl.current && (
            <SocialLink
              href={twitterLinkUrl.current}
              aria-label={`Share on Twitter`}
              target="_blank"
            >
              <X />
            </SocialLink>
          )}
        </SocialLinks>
      </Wrapper>
    );
  }
};

export default Share;

const Wrapper = styled.div`
  position: relative;
  height: auto;
  border-radius: 25px;
  border: 1px solid ${colors.humeBlack700};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  width: 100%;
  flex-shrink: 0;
  margin: auto;
  margin-bottom: 75px;

  ${mq.desk} {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
  }

  ${mq.contained} {
    max-width: unset;
  }
`;

const Title = styled.div`
  margin-bottom: 7px;

  ${mq.contained} {
    margin-bottom: 0.36vw;
  }
`;

const ShareTitle = styled.div`
  margin-bottom: 15px;
  margin-top: 30px;

  ${mq.contained} {
    margin-bottom: 0.78vw;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${mq.contained} {
    gap: 0.52vw;
  }
`;

const SocialLink = styled.a`
  border-radius: 50%;
  border: 1px solid currentColor;
  height: 40px;
  position: relative;
  width: 40px;

  svg {
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 40px;
  }

  ${mq.contained} {
    height: 2.08vw;
    width: 2.08vw;
    border-width: 0.05vw;

    svg {
      width: 2.08vw;
    }
  }

  &:hover {
    background-color: ${colors.humeBlack700};

    svg {
      path {
        fill: ${colors.humeTan400};
      }
    }
  }
`;
