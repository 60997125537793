import React, { useEffect, useState } from 'react';
import { Heading2, TextBodyLarge } from 'components/TextStyles';
import {
  Wrapper,
  Inner,
  Content,
  BackToHome,
  TitleDate,
  VR,
  PodcastWrapper,
} from './index.style';
import Button from 'components/Button';
import IconArrowheadLeft from 'components/_svgs/IconArrowheadLeft';
import { buttonVariants } from 'components/Button/index.style';
import BlogTag from 'components/BlogTag';
import PageTransitionLink from 'components/PageTransitionLink';
import Podcast from 'components/Podcast';
import Routes from 'routes';

import { useMedia } from 'hooks/useMedia';
import { useCallback } from 'react';

const ArticleHero = ({ title, date, theme, typeOfPost, author, podcast }) => {
  const [podcastPadding, setPodcastPadding] = useState(0);
  const calcPadding = useMedia(
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
  );

  const calcPodcastPadding = useCallback(() => {
    if (calcPadding) {
      const tag = document.getElementById('blog-tag');
      const tagWidth = tag.getBoundingClientRect().width;

      setPodcastPadding(tagWidth + 37);
    } else {
      setPodcastPadding(0);
    }
  }, [calcPadding]);

  useEffect(() => {
    calcPodcastPadding();
    window.addEventListener('resize', calcPodcastPadding);

    return () => {
      window.removeEventListener('resize', calcPodcastPadding);
    };
  }, [calcPodcastPadding]);

  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <Wrapper>
      <Inner theme={theme}>
        <BackToHome>
          <Button
            variant={buttonVariants.plain}
            iconLeft={<IconArrowheadLeft />}
            as={`a`}
            href={Routes.hub}
          >
            Back to Home
          </Button>
        </BackToHome>
        <Content>
          <BlogTag type={typeOfPost} />
          <VR />
          <TitleDate>
            <Heading2 as="h1">{title}</Heading2>
            <TextBodyLarge as="div">
              {author ? (
                <>
                  By{' '}
                  <PageTransitionLink
                    to={`/about`}
                    scrollSection={author.name.replace(' ', '-')}
                  >
                    {author.name}
                  </PageTransitionLink>{' '}
                  on{' '}
                </>
              ) : (
                <>Published on </>
              )}
              {formattedDate}
            </TextBodyLarge>
          </TitleDate>
        </Content>
        <PodcastWrapper padding={podcastPadding}>
          <Podcast podcast={podcast} />
        </PodcastWrapper>
      </Inner>
    </Wrapper>
  );
};

export default ArticleHero;
