import React, { useState } from 'react';
import Img from 'gatsby-image';
import Hammer from 'react-hammerjs';

import Container from '../_layouts/Container';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import BlogTag from 'components/BlogTag';

import {
  PostMain,
  PostGridWrap,
  PostGrid,
  PostHead,
  Post,
  PostImage,
  PostType,
  PostHeading,
  PostText,
  PostButton,
  RecentHeading,
  PostCount,
} from './index.style';

import Button from '../Button';
import { buttonVariants } from '../Button/index.style';
import { Heading5, TextCaption, TextCTA } from '../TextStyles';
import RichText from '../RichText';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';
import PageTransitionLink from '../PageTransitionLink';
import AnimateImageIn from '../_animation/AnimateImageIn';
import AnimateFadeIn from '../_animation/AnimateFadeIn';
import { breakpoints } from '../../styles/vars/breakpoints.style';
import Routes from '../../routes';

const PostList = ({ content }) => {
  const [current, setCurrent] = useState(0);

  const handleSwipe = (e) => {
    if (window.innerWidth < breakpoints.tabletL) {
      if (e.direction === 4) {
        //left
        if (current > 0) {
          setCurrent(current - 1);
        }
      } else if (e.direction === 2) {
        //right
        if (current < 2) {
          setCurrent(current + 1);
        }
      }
    }
  };

  return (
    <PostMain>
      <Container>
        <Grid>
          <GridItem tabletL={14} tabletLStart={2} desk={12} deskStart={3}>
            <RecentHeading>
              <PostHead>
                <AnimateFadeIn delay={0.2}>
                  <TextCTA>Recent Posts</TextCTA>
                </AnimateFadeIn>
                <AnimateFadeIn delay={0.2}>
                  <PostCount>
                    <TextCaption>
                      {current + 1} / {content.length}
                    </TextCaption>
                  </PostCount>
                </AnimateFadeIn>
              </PostHead>
            </RecentHeading>
            <Hammer onSwipe={handleSwipe}>
              <PostGridWrap>
                <PostGrid current={current}>
                  {content.map((item, key) => {
                    return (
                      <Post key={key} num={key} type={item.typeOfPost}>
                        <PostImage>
                          <PageTransitionLink
                            to={`${Routes.hub}/${item.slug}/`}
                            aria-label={
                              item.typeOfPost === 'Podcast'
                                ? `Listen to Podcast`
                                : item.typeOfPost === 'Article'
                                ? `Read Article`
                                : item.typeOfPost === 'Demo'
                                ? `View Demo`
                                : `Read More`
                            }
                          >
                            <AnimateImageIn>
                              {item.heroImage && (
                                <Img
                                  fluid={item.heroImage.fluid}
                                  alt={item.heroImage.title}
                                />
                              )}
                            </AnimateImageIn>
                          </PageTransitionLink>
                        </PostImage>
                        <PostType>
                          <BlogTag type={item.typeOfPost} />
                        </PostType>
                        <PostHeading>
                          <AnimateFadeIn delay={0.4}>
                            <Heading5>
                              {' '}
                              <PageTransitionLink
                                to={`${Routes.hub}/${item.slug}/`}
                                aria-label={
                                  item.typeOfPost === 'Podcast'
                                    ? `Listen to Podcast`
                                    : item.typeOfPost === 'Article'
                                    ? `Read Article`
                                    : item.typeOfPost === 'Demo'
                                    ? `View Demo`
                                    : `Read More`
                                }
                              >
                                {item.heroTitle}
                              </PageTransitionLink>
                            </Heading5>
                          </AnimateFadeIn>
                        </PostHeading>
                        {item.heroCopy && (
                          <PostText>
                            <RichText content={item.heroCopy} delay={0.6} />
                          </PostText>
                        )}
                        <AnimateFadeIn delay={0.8}>
                          <PostButton>
                            <Button
                              as={`a`}
                              variant={buttonVariants.plain}
                              href={`${Routes.hub}/${item.slug}/`}
                              iconRight={<IconArrowheadRight />}
                            >
                              {item.typeOfPost === 'Podcast'
                                ? `Listen to Podcast`
                                : item.typeOfPost === 'Article'
                                ? `Read Article`
                                : item.typeOfPost === 'Demo'
                                ? `View Demo`
                                : `Read More`}
                            </Button>
                          </PostButton>
                        </AnimateFadeIn>
                      </Post>
                    );
                  })}
                </PostGrid>
              </PostGridWrap>
            </Hammer>
          </GridItem>
        </Grid>
      </Container>
    </PostMain>
  );
};

export default PostList;
