import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { grid } from '../../styles/vars/grid.style';
import { mq } from '../../styles/vars/media-queries.style';

export const PostMain = styled.div`
  border-top: 0.1rem solid ${colors.humeBlack700};
  ${clamp('padding-top', 80, 100)}
  position: relative;
  background-color: ${colors.neutralWhite};
`;

export const RecentHeading = styled.div`
  display: inline-block;
  margin-bottom: 3rem;
  width: 100%;
`;

export const PostHead = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const PostCount = styled.div`
  display: inline-block;

  ${mq.tabletL} {
    display: none;
  }
`;

export const Post = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: calc(100% - 3.4rem);

  ${mq.tabletL} {
    width: auto;
  }

  ${({ num }) => {
    return num !== 0
      ? `position: absolute;
      top: 0;
      left: 0;
      transform: translateX(calc((100% * ${num}) + (3.4rem * ${num})));

      ${mq.tabletL} {
        position: relative;
        top: auto;
        left: auto;
        transform: translateX(0);
      }
      `
      : ``;
  }}
`;

export const PostGridWrap = styled.div`
  margin-left: -2.2rem;
  margin-right: -2.2rem;
  overflow: hidden;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  position: relative;
`;

export const PostGrid = styled.div`
  position: relative;
  transition: transform 1s ${easings.text.in};

  ${({ current }) => ` transform: translateX(calc(-100% * ${current}));

  ${mq.tabletL} {
        transform: translateX(0);
      }`}

  ${mq.tabletL} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    ${clamp('gap', grid.gap.min, grid.gap.max)}
    grid-auto-rows: minmax(10rem, auto);
  }
`;

export const PostType = styled.div`
  ${clamp('margin-bottom', 14, 36)}
  ${clamp('margin-top', 21, 28)}
`;
export const PostHeading = styled.div`
  ${clamp('margin-bottom', 10, 20)}
`;

export const PostText = styled.div`
  ${clamp('margin-bottom', 97, 170)}
  ${clamp('padding-bottom', 27, 50)}
`;

export const PostButton = styled.div`
  ${clamp('bottom', 70, 120)}

  position: absolute;
`;

export const PostImage = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;

  a {
    display: block;
  }
`;
