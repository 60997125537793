import React, { useRef } from 'react';
import { VideoWrap } from './index.style';

const VideoComponent = ({ videoProps, videoType }) => {
  const videoRef = useRef();
  const videoIframe = useRef();
  const youtubeIframe = useRef(null);

  return (
    <VideoWrap ref={videoRef}>
      <div className="embed-container">
        {videoType === 'Vimeo' ? (
          <iframe
            src={`https://player.vimeo.com/video/${videoProps}?autoplay=0&loop=0&muted=0&title=0&sidedock=0&controls=1&autopause=1`}
            height="315"
            width="560"
            className={`video`}
            id={`heroVideo`}
            frameBorder="0"
            title={`Video`}
            ref={videoIframe}
            scrolling="no"
          ></iframe>
        ) : (
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoProps}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            scrolling="no"
            ref={youtubeIframe}
          ></iframe>
        )}
      </div>
    </VideoWrap>
  );
};

export default VideoComponent;
